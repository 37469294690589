import React from 'react';
import styled from 'styled-components';

import Flex, { FlexItem } from 'styled-flex-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleRight } from '@fortawesome/free-solid-svg-icons';

import MainContainer from './layouts/MainContainer';
import Logo from './common/Logo.style';
import Link from './Link';
import WeeklySchedule from './WeeklySchedule';
import ClosedDayLabel from './ClosedDayLabel';
import Online365Label from './Online365Label';
import Address from './Address';
import ContactButton from './ContactButton';
import FacebookButton from './FacebookButton';
import TwitterButton from './TwitterButton';

import ClinicLogoImg from '../assets/img/clinic_logo.png';

const ListItemText = styled.span`
  font-size: 14px;
  font-weight: bold;
  color: #666666;
  font-family: 'M PLUS Rounded 1c', TsukuARdGothic-Regular;
`;

const FooterBar = styled.div`
  height: 40px;
  background-color: #f7b13c;
`;

const FooterBarBrandTitle = styled.span`
  font-size: 28px;
  color: #ffffff;
`;

const MenuItem = ({ name, to, external = false }) => (
  <Link to={to} external={external}>
    <Flex
      css={`
        height: 50px;
        width: 150px;
      `}
      column
      justifyCenter
    >
      <Flex
        css={`
          margin-left: 15px;
        `}
      >
        <Flex center>
          <FontAwesomeIcon
            icon={faChevronCircleRight}
            style={{ fontSize: 12, marginRight: 10, marginBottom: 2 }}
            color="#000"
          />
        </Flex>
        <Flex center>
          <ListItemText>{name}</ListItemText>
        </Flex>
      </Flex>
    </Flex>
  </Link>
);

const Copyright = styled.span`
  font-size: 10px;
  color: #666666;
`;

const Footer = ({ size }) => (
  <footer>
    <FooterBar>
      <MainContainer size={size}>
        <Flex justifyEnd={size !== 'sm'} center={size === 'sm'}>
          <FlexItem>
            <Flex
              column
              justifyCenter
              css={`
                height: 40px;
                margin-right: 10px;
              `}
            >
              <Logo src={ClinicLogoImg} size="sm" />
            </Flex>
          </FlexItem>
          <FlexItem>
            <Flex
              column
              justifyCenter
              css={`
                height: 40px;
              `}
            >
              <FooterBarBrandTitle>HIRAHATA CLINIC</FooterBarBrandTitle>
            </Flex>
          </FlexItem>
        </Flex>
      </MainContainer>
    </FooterBar>
    <MainContainer size={size}>
      <Flex
        wrap="true"
        contentStretch
        alignStretch
        justifyBetween={size === 'lg'}
        justifyAround={size !== 'lg'}
      >
        <Flex justifyBetween>
          <Flex column center size={size}>
            <MenuItem name="HOME" to="/" />
            <MenuItem name="内視鏡" to="/endoscopy" />
            <MenuItem name="アクセス" to="/access" />
            <MenuItem name="当院について" to="/about" />
          </Flex>
          <Flex column center size={size}>
            <MenuItem name="診療科" to="/departments" />
            <MenuItem name="健診・人間ドック" to="https://www.hirahata.net/" external />
            <MenuItem name="受付時間" to="/schedule" />
            <MenuItem name="院長紹介" to="/dr" />
          </Flex>
        </Flex>
        {/*  
        {size === 'lg' && (
          <Flex column center size={size}>
            <WeeklySchedule size="sm" />
           
            <Flex justifyBetween>
              <FlexItem>
                <ClosedDayLabel />
              </FlexItem>
              <FlexItem>
                <Online365Label />
              </FlexItem>
            </Flex>
           
          </Flex>
        )}
        */}
        <Flex column center size={size}>
          <FlexItem
            css={`
              margin: 5px 0;
            `}
          >
            <Address />
          </FlexItem>
          {size === 'sm' && (
            <span
              css={`
                font-size: 12px;
                color: #666666;
              `}
            >
              休診日：日曜・祝日
            </span>
          )}
          {size === 'sm' && (
            <Flex
              css={`
                margin: 5px 0;
                align-self: stretch;
              `}
            >
              <ContactButton tel="tel:0334003288" label="電話でお問い合わせ" fullWidth={true} />
            </Flex>
          )}
          <Flex
            css={`
              margin: 5px 0;
              align-self: stretch;
            `}
          >
            <ContactButton fullWidth={true} buttonColor="#f0862e" />
          </Flex>
          <FlexItem
            css={`
              margin: 5px 0;
            `}
          >
            <Flex
              justifyBetween
              css={`
                width: 276px;
              `}
            >
              <FlexItem>
                <FacebookButton />
              </FlexItem>
              <FlexItem>
                <TwitterButton />
              </FlexItem>
            </Flex>
          </FlexItem>
        </Flex>
      </Flex>
    </MainContainer>
    <MainContainer size={size}>
      <Flex
        center
        css={`
          margin-top: 10px;
          margin-bottom: 10px;
        `}
      >
        <Copyright>©️HIRAHATA CLINC all rights reserved.</Copyright>
      </Flex>
    </MainContainer>
  </footer>
);

export default Footer;
